<template>
    <div class="invite">
        <div class="header">
            <van-nav-bar :title="$t('home.yq_auth')" left-arrow @click-left="$router.go(-1)" />
        </div>
        <div class="maincontent">
            <div class="spacing">
                <div class="spacing-header">
                    <!--<div class="headers_ww"></div>-->
                    <div class="mains">
                        <div class="conts">
                            <h2>{{ $t('invite.code') }}</h2>
                            <p>{{ user.invite_code }}</p>
                            <!-- <img :src="require('../../assets/img/code.png')" /> -->
                            <img :src="imgUrl" alt="" />
                            <div class="links">
                                <span @click.prevent="copyurl" class="icon-copy" :data-clipboard-text="urls">{{ $t('invite.copy') }}</span>
                            </div>
                        </div>
                    </div>
                </div>
                <!--<div class="lines">-->
                <!--    <div class="conts">-->
                <!--        <div class="line"></div>-->
                <!--        <div class="quare quare-left"></div>-->
                <!--        <div class="quare quare-rigth"></div>-->
                <!--    </div>-->
                <!--</div>-->
                <div class="spacing-main">
                    <div class="conts">
                        <div class="conts-header">
                            <div class="header_qwe">{{ $t('invite.title') }}</div>
                            <div class="statistics">
                                <div class="statistics-conts">
                                    <div class="ert">
                                      <p class="title">{{ $t('invite.certified') }}</p>
                                      <p>{{ certifiedNum }}</p>
                                    </div>
                                    <div class="ert">
                                      <p class="title">{{ $t('invite.notCertified') }}</p>
                                      <p>{{ notCertifiedNum }}</p>
                                    </div>
                                    <div class="lines-cont"></div>
                                </div>
                            </div>
                        </div>

                        <div class="cont-list">
                            <div class="list" v-for="(item, index) in list" :key="index">
                                <div class="list-headers">
                                    <h2>{{ index + 1 === 1 ? $t('invite.ones') : (index + 1 === 2 ? $t('invite.twos') : $t('invite.threes')) }}</h2>
                                </div>
                                <div class="list-cont">
                                    <div class="statistics-conts">
                                        <div class="ert">
                                          <p class="title">{{ $t('invite.certified') }}</p>
                                          <p>{{ item.certified }}</p>
                                        </div>
                                        <div class="ert">
                                          <p class="title">{{ $t('invite.notCertified') }}</p>
                                          <p>{{ item.notCertified }}</p>
                                        </div>
                                        <div class="lines-cont"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import Clipboard from 'clipboard'
export default {
  data() {
    return {
      code: {
        img: ''
      },
      list: [
        {
          certified: 0,
          notCertified: 0
        },
        {
          certified: 0,
          notCertified: 0
        },
        {
          certified: 0,
          notCertified: 0
        }
      ],
      certifiedNum: 0,
      notCertifiedNum: 0,
      urls: '',
      imgUrl: '',
      user: {}
    }
  },
  created() {
    this.init()
  },
  mounted() {
    // this.certifiedNum = this.list.map(item => item.certified).reduce((prev, curr) => prev + curr, 0)
    // this.notCertifiedNum = this.list.map(item => item.notCertified).reduce((prev, curr) => prev + curr, 0)
    this.urls = window.location.href
    // this.init();
  },
  methods: {
    // 复制地址
    copyurl() {
      const clipboard = new Clipboard('.icon-copy')
      clipboard.on('success', e => {
        this.$toast.success(this.$t('common.success'))
      })
    },
    async init() {
      const { data } = await this.$http.get('/home/commission/index')
      // console.log('邀请返回的数据: ', data.data)
      if (data.code === 200) {
        this.certifiedNum = data.data.total?.oauth_users
        this.notCertifiedNum = data.data.total?.no_oauth_users
        let arrLists = []
        if (data.data.first) {
          arrLists.push({
            certified: data.data.first.oauth_users || 0,
            notCertified: data.data.first.no_oauth_users || 0
          })
        }
        if (data.data.second) {
          arrLists.push({
            certified: data.data.second.oauth_users || 0,
            notCertified: data.data.second.no_oauth_users || 0
          })
        }
        if (data.data.three) {
          arrLists.push({
            certified: data.data.three.oauth_users || 0,
            notCertified: data.data.three.no_oauth_users || 0
          })
        }
        this.list = arrLists
        this.imgUrl = data.data.invite_qrcode || ''
        this.user = data.data || ''
        this.urls = window.location.origin + '/#/register?inviter_code=' + data.data.invite_code
        // console.log('邀请返回的数据: ', arrLists)
      } else {
        this.$toast.fail(data.msg)
      }
    }
  }
}
</script>
<style lang="less" scoped>
.invite {
    min-height: 100%;
}
.maincontent {
  padding-top: 50px;
  flex: 1;
  font-size: 0.93333rem;
  height: 100vh;
    .spacing {
        padding: 1.2rem 1rem;
        //background: #0055ff;
      background: url("../../assets/img/invite/invite-bg.png") no-repeat;
      background-size: cover;
        .spacing-header {
            //.headers_ww {
            //    width: 100%;
            //    height: 1rem;
            //    background: #FEB545;
            //    border-radius: 1rem;
            //
            //}
            .mains {
                padding: 0 1.2rem;
                margin-top: 1rem;
                .conts {
                    min-height: 10rem;
                    //background: #fff;
                    padding-bottom: 1rem;
                    h2 {
                      font-weight: bold;
                      font-size: 1.125rem;
                      color: #FFFFFF;
                      margin-bottom: 1rem;
                    }
                    p {
                      font-weight: bold;
                      font-size: 1.5rem;
                      color: #30B5FF;
                      margin-bottom: 1rem;
                    }
                    img {
                      display: inline-block;
                      width: 9.4rem;
                      height: 9.4rem;
                      margin-bottom: 1rem;
                    }
                    .links {
                      padding: 0 4rem;
                      margin-bottom: 1rem;
                        span {
                            display: inline-block;
                            width: 100%;
                          font-weight: 800;
                          font-size: 0.9375rem;
                            padding: 0.8rem 0;
                            background: #004ea3;
                            color: #fff;
                            line-height: 1;
                            border-radius: 0.4rem;
                        }
                    }
                }
            }
        }

        .lines {
            padding: 0 1.2rem;
            margin-top: -0.1rem;

            .conts {
                background: #fff;
                height: 1.6rem;
                position: relative;

                .line {
                    position: absolute;
                    height: 1px;
                    width: 100%;
                    top: 50%;
                    border-top: 1px dashed rgba(0, 85, 255, .2);
                }

                .quare {
                    height: 1.6rem;
                    width: 1.6rem;
                    background: #0055ff;
                    border-radius: 1.6rem;
                    position: absolute;
                    top: 0;
                }

                .quare-left {
                    left: -0.8rem;
                }

                .quare-rigth {
                    right: -0.8rem;
                }
            }

        }

        .spacing-main {
            padding: 0 1.2rem;
            margin-top: -0.1rem;

            .conts {
                min-height: 4rem;

                .conts-header {
                    margin-top: 1rem;
                    border-radius: 0.4rem;
                    position: relative;

                    .header_qwe {
                      text-align: center;
                      font-weight: 500;
                      font-size: 1.125rem;
                      color: #FFFFFF;
                      margin-bottom: 0.875rem;
                    }

                    .statistics {
                      background: url("../../assets/img/invite/statistics-bg.png") no-repeat;
                      background-size: 100% 100%;
                        .statistics-conts {
                          padding: 2.34375rem 0.3125rem;
                            display: flex;
                            justify-content: space-between;
                            position: relative;
                            .ert {
                                width: 49%;
                                p {
                                  font-weight: 500;
                                  font-size: 0.9375rem;
                                  color: #fff;
                                  &.title{
                                    color: #30B5FF;
                                    font-weight: 500;
                                    margin-bottom: 1.5rem;
                                  }
                                }
                                span {
                                    font-size: 0.833rem;
                                    color: #999;
                                    line-height: 1;
                                }
                            }

                            .lines-cont {
                              position: absolute;
                              width: 0.25rem;
                              height: 2.0625rem;
                              left: 50%;
                              top: 3.6rem;
                              margin-left: 1px;
                              background: url("../../assets/img/invite/statistics-lines.png") no-repeat;
                              background-size: cover;
                            }
                        }

                    }
                }

                .cont-list {
                  margin-bottom: 2.5rem;
                    .list {
                      background: url("../../assets/img/invite/statistics-bg.png") no-repeat;
                      background-size: 100% 100%;
                        padding: 1.25rem 1.5625rem;
                        margin-top: 1rem;
                        border-radius: 0.4rem;
                        .list-headers {
                            text-align: left;
                            position: relative;
                            h2 {
                              font-weight: 500;
                              font-size: 0.9375rem;
                              color: #30B5FF;
                              padding-left: 0.46875rem;
                              margin-bottom: 0.78125rem;
                              &:before {
                                content: '';
                                width: 0.125rem;
                                height: 0.625rem;
                                background-color: #30B5FF;
                                border-radius: 0.0625rem;
                                position: absolute;
                                top: 4px;
                                left: 0;
                              }
                            }
                        }
                        .list-cont {
                          margin-bottom: 0.9375rem;
                            .statistics-conts {
                                display: flex;
                                justify-content: space-between;
                                position: relative;
                                .ert {
                                    width: 49%;
                                    height: 3.4rem;
                                    p {
                                        font-size: 0.933rem;
                                        color: #fff;
                                        line-height: 1;
                                        font-weight: bold;
                                      &.title{
                                        font-weight: 500;
                                        font-size: 0.9375rem;
                                        margin-bottom: 1.1875rem;
                                      }
                                    }
                                }
                                .lines-cont {
                                  position: absolute;
                                  width: 0.25rem;
                                  height: 2.0625rem;
                                  left: 50%;
                                  top: 0.0625rem;
                                  margin-left: 1px;
                                  background: url("../../assets/img/invite/statistics-lines.png") no-repeat;
                                  background-size: cover;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
</style>
